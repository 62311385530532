@import './styles-variables';
* {
    font-family: 'Source Sans Pro';
    &:active,
     :focus {
        outline: none !important;
    }
}

.mat-drawer-container {
    background-color: #F2F2F2 !important;
}

.mat-menu-content {
    fa-icon {
        position: relative;
        top: 2px;
        left: 4px;
    }
}

p {
    a {
        border-bottom: 1px solid;
        color: $yellow-color;
    }
}

.mat-card {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 0 !important;
}

// TODO : REMOVE THESE STYLE FROM HERE AND PUT IT IN THEME FILE
.full-width {
    width: 100%;
}

.small-width {
    width: 30%;
}

.medium-width {
    width: 60%;
}

.lpa-form {
    .mat-form-field {
        background-color: white;
        margin-top: 20px;
        .mat-form-field-suffix .mat-icon {
            font-size: 112%;
            color: #636363;
        }
        .mat-form-field-wrapper {
            padding-bottom: 0px;
            padding-right: 10px;
            .mat-form-field-label-wrapper {
                left: 10px;
            }
            .mat-form-field-infix {
                padding-bottom: 10px;
                padding-left: 10px;
            }
        }
        .mat-form-field-underline {
            bottom: 0em;
        }
        .mat-error {
            margin-top: 13px;
        }
    }
    .lpa-price-inputs {
        margin-top: 20px;
        .lpa-price-label {
            padding-bottom: 4px;
            font-size: 14px;
        }
        .lpa-price-sublabel {
            display: inline-block;
            padding: 0px 8px;
            vertical-align: middle;
            height: 40px;
        }
        .mat-form-field {
            display: inline-block;
            margin-right: 10px;
            margin-top: 0px
        }
    }
    .mat-help-tooltip {
        position: absolute;
        top: 0;
        right: -45px;
    }
}

.lpa-table {
    width: 100%;
    th.mat-header-cell {
        color: black;
        font-weight: bold;
        font-size: 14px;
    }
}

.lpa-button {
    font-weight: bold !important;
    height: 40px;
    min-width: 120px !important;
    border-radius: 0px !important;
    .mat-button-wrapper {
        color: $yellow-color;
    }
}

.lpa-dialog .mat-dialog-container {
    background-color: $grey-background !important;
}

.lpa-nav {
    .mat-tab-label-active {
        font-weight: bold;
        color: black;
        background-color: white;
    }
    .mat-tab-body {
        overflow: initial;
        width: 100%;
        height: 100%;
    }
    .mat-tab-body .mat-tab-body-content {
        overflow: initial;
        width: 100%;
        height: 100%;
    }
}

.save-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    min-height: 70px;
    text-align: center;
    .lpa-button {
        margin-top: 15px;
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background: black !important;
}

.mat-slide-toggle {
    .mat-slide-toggle-bar {
        width: 38px;
        height: 22px;
        border-radius: 13px;
    }
    .mat-slide-toggle-thumb-container {
        top: 1px;
        left: 1px;
    }
    .mat-slide-toggle-thumb {
        background-color: white !important;
    }
}

.default-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.default-theme .mat-checkbox .mat-ripple-element {
    background: black !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.lpa-table-container{
    overflow-x: scroll;
}