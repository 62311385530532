@use '@angular/material' as mat;
@import 'styles-variables';
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'styles-reset';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@import './themes/default-theme';
@import './themes/light-theme';
@import './themes/black-theme';
@import './themes/nature-theme';
@import 'styles-reset.scss-theme';
@import './app/app/app.component.scss-theme';
@import './app/shared/big-input/big-input/big-input.component.scss-theme';
@import './app/features/auth/login/login.component.scss-theme';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@mixin custom-components-theme($theme) {
  @include lpa-styles-reset-theme($theme);
  @include lpa-app-component-theme($theme);
  @include lpa-about-component-theme($theme);
  @include lpa-big-input-component-theme($theme);
}

.default-theme {
  @include mat.all-legacy-component-themes($lpa-theme);
  @include custom-components-theme($lpa-theme);
}

.light-theme {
  @include mat.all-legacy-component-themes($lpa-light-theme);
  @include custom-components-theme($lpa-light-theme);
}

.black-theme {
  @include mat.all-legacy-component-themes($lpa-black-theme);
  @include custom-components-theme($lpa-black-theme);
}

.nature-theme {
  @include mat.all-legacy-component-themes($lpa-nature-theme);
  @include custom-components-theme($lpa-nature-theme);
}

/* source-sans-pro-300italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/source-sans-pro-v13-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
    url('assets/fonts/source-sans-pro-v13-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-300italic.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

/* source-sans-pro-300 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/source-sans-pro-v13-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('assets/fonts/source-sans-pro-v13-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('assets/fonts/source-sans-pro-v13-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-300.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

/* source-sans-pro-regular - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/source-sans-pro-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('assets/fonts/source-sans-pro-v13-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-regular.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

/* source-sans-pro-italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/source-sans-pro-v13-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
    url('assets/fonts/source-sans-pro-v13-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-italic.woff') format('woff'),
    /* Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-italic.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

/* source-sans-pro-600 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/source-sans-pro-v13-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('assets/fonts/source-sans-pro-v13-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('assets/fonts/source-sans-pro-v13-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-600.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

/* source-sans-pro-600italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/source-sans-pro-v13-latin-600italic.eot');
  /* IE9 Compat Modes */
  src: local('Source Sans Pro SemiBold Italic'),
    local('SourceSansPro-SemiBoldItalic'),
    url('assets/fonts/source-sans-pro-v13-latin-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/source-sans-pro-v13-latin-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('assets/fonts/source-sans-pro-v13-latin-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('assets/fonts/source-sans-pro-v13-latin-600italic.svg#SourceSansPro')
      format('svg');
  /* Legacy iOS */
}

// custom angular-material styles
.mat-dialog-container {
  border-radius: 0 !important;
}

@font-face {
  font-family: 'menu-fonts';
  src: url('assets/fonts/menu-fonts.eot?7ret15');
  src: url('assets/fonts/menu-fonts.eot?7ret15#iefix')
      format('embedded-opentype'),
    url('assets/fonts/menu-fonts.ttf?7ret15') format('truetype'),
    url('assets/fonts/menu-fonts.woff?7ret15') format('woff'),
    url('assets/fonts/menu-fonts.svg?7ret15#menu-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'menu-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Export:before {
  content: '\e913';
}

.icon-Bruger-menu:before {
  content: '\e907';
}

.icon-Users:before {
  content: '\e908';
}

.icon-Editorial:before {
  content: '\e909';
}

.icon-Card:before {
  content: '\e90a';
}

.icon-Union:before {
  content: '\e90b';
}

.icon-Subscription:before {
  content: '\e90c';
}

.icon-Parking:before {
  content: '\e90d';
}

.icon-Car:before {
  content: '\e90e';
}

.icon-Client:before {
  content: '\e90f';
}

.icon-Promos:before {
  content: '\e910';
}

.icon-Unit:before {
  content: '\e911';
}

.icon-Live:before {
  content: '\e912';
}

.icon-Booking:before {
  content: '\e914';
}

.icon-Dashboard:before {
  content: '\e915';
}
